<template>
  <v-container class="osu">
    <v-container class="os">
      <v-card class="mx-auto rounded-xl" elevation="5">
        <v-row class="pa-8">
          <v-col class="text-center" cols="12" md="12">
            <h1>Our Partnership</h1>
          </v-col>
          <v-col cols="12" md="6">
            <div class="pa-0">
              <v-img
                class="mt-16"
                style="position: relative"
                src="@/assets/Alibaba.png"
                max-height="800"
                max-width="800"
              ></v-img>
            </div>
            <!-- <h2 ></h2>
              <br />
            <h4>Below are some of the our Division Business</h4>-->
          </v-col>
          <v-col cols="12" md="6">
            <h2>Alibaba Cloud is a cloud computing and artificial intelligence provider that provides services to thousands of enterprises, developers, and government organizations in more than 200 countries and regions. Committed to the success of its customers, Alibaba Cloud provides reliable and secure cloud computing and data processing capabilities as part of its online solution. Alibaba Cloud has become the official cloud service partner of the Olympics. Alibaba Cloud has a range of customers and partners who can help our partners and our customers achieve specific goals.</h2>
          </v-col>
          <v-col cols="12" md="6"> 
            <div class="pa-0">
            <v-img
                class="mr-auto ml-auto mt-0"
                style="position: relative"
                src="@/assets/AKP21.png"
                max-height="500"
                max-width="350"
              ></v-img>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <h2 class="mt-5">PT Keberlanjutan Strategis Indonesia, in this case, is legally acting for and on behalf of PT Keberlanjutan Strategis Indonesia, domiciled at Jl.Cikutra Baru Raya complex Jl.Cikutra Baru IV No.10, Bandung City, West Java 40123, from now on referred to as the FIRST PARTY: Chairman of the Asosiasi Konsultan Pajak Publik Indonesia (AKP2I), in this case acting for and on behalf of the Asosiasi Konsultan Pajak Publik Indonesia (AKP21), who is resident in Jakarta and has an address at Fatmawati Mas shophouse Block 1 No. 113 4th Floor, Jl.RS, Fatmawati Raya No.20 Kec.CIlandak South Jakarta 12430, from now on referred to as the SECOND PARTY..</h2>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-container>
</template>
  
  <script>
export default {};
</script>
  
  <style scoped>
.v-container.osu {
  max-width: 100%;
}

.v-container.os {
  padding: 10px;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.os {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.os {
    max-width: 1281px;
  }
}

.v-container {
  padding-top: 50px;
  padding-bottom: 50px;
}

h1 {
  font-size: 34px;
  font-family: Montserrat;
}
h2 {
  font-size: 18px;
  font-family: Montserrat;
  color: #858383;
  font-style: Regular;
  text-align: justify;
  text-justify: inter-word;
}

/* @media (max-width: 991px){
    h1{
      font-size: 1rem;
    }
    h4 {
    font-size: 1rem;
    
  }
  } */
.v-card:hover {
  background-color: #eef2e6;
}
</style>