<template>
  <v-container flex>
    <div>
      <h1 class="text-center">Corporate Social Responsibility</h1>
      <br />
    </div>
    <v-row justify="center">
      <v-col cols="12" md="6" lg="6">
        <v-card cla @click="navigate" role="link" variant="outlined-white">
          <v-img class="align-end text-white" height="600" src="@/assets/yayasanPuntang.jpg"></v-img>
          <!-- <v-card-title>Bill Walsh leadership lessons</v-card-title> -->
        </v-card>
      </v-col>
      <!-- <v-col cols="12" md="6" lg="6">
        <router-link to="/about" custom v-slot="{ navigate }">
          <v-card @click="navigate" role="link" variant="outlined-white">
            <v-img
              class="align-end text-white"
              height="600"
              src="@/assets/yayasanPuntang.jpg"
              
            >
            </v-img>
          </v-card></router-link
        >
      </v-col>-->
      <!-- <v-col cols="12" md="4" lg="4"
        ><v-card variant="outlined-white">
          <v-img
            class="align-end text-white"
            height="400"
            src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
            cover
          >
          </v-img>
          <v-card-title>Bill Walsh leadership lessons</v-card-title>
        </v-card> </v-col
      >-->
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.v-img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.v-img:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
/* .v-card:hover {
  background-color: #eef2e6;
  color: rgb(19, 19, 19);
} */
h1 {
  font-family: Montserrat;
}
h2 {
  font-family: Montserrat;
}

h4 {
  font-family: Montserrat;
  font-style: Regular;

  color: #858383;
}
</style>