<template>
  <v-container class="oo">
  <v-container class="ob">
      <v-card class="mx-auto animate__animated animate__bounce rounded-xl" elevation="5">
        <v-row class="pa-10">
          <v-col cols="12" md="3">
            <div class="pa-0">
              <h1>Our Division Business</h1>
              <h4>Below are some of the our Division Business</h4>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <a href="https://catrainstitute.co.id/" target="blank_">
              <v-img
                class="pa-5"
                style="position: relative"
                src="@/assets/catraLogoNew.png"
                max-height="800"
                max-width="800"
              ></v-img>
            </a>
          </v-col>
          <v-col cols="12" md="4">
            <a href="https://vikramainstitute.co.id/" target="blank_">
            <v-img
              class="pa-5"
              style="position: relative"
              src="@/assets/VikramaLogo.png"
              max-height="800"
              max-width="800"
            ></v-img>
          </a>
          </v-col>
        </v-row>
      </v-card>
  </v-container>
</v-container>
</template>

<script>
export default {};
</script>

<style scoped>

.v-container.ob {
    padding: 10px;
}

/* Tablet */
@media (min-width:600px) {
  .v-container.ob {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width:900px) {
  .v-container.ob {
    max-width: 1281px;
  }
}
.v-container.oo {
  padding-top: 30px;
}
h1 {
  font-family: Montserrat;
}
h2 {
  font-family: Montserrat;
}

h4 {
  font-family: Montserrat;
  font-style: Regular;
  color: #858383;
}

.v-img:hover {
  background-color: #eef2e6;
}
</style>