<template>
  <v-container class="vv">
  <v-container class="vu">
    <div>
      <h1 class="text-center">Visit University</h1>
    </div>
    <v-row justify="center">
      <v-col cols="12" md="3" lg="3">
        <v-card @click="navigate" role="link" variant="outlined-white">
          <v-img class="align-end text-white" height="300" src="@/assets/nusaCendana.jpg"></v-img>
          <v-row>
            <v-col cols="10" md="10">
              <h3>Visit to Nusa Cendana University</h3>
            </v-col>
            <v-col cols="1" md="1">
              <v-icon class="pt-5" icon="fa-solid fa-chevron-right fa-2xs" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" lg="3">
        <v-card variant="outlined-white">
          <v-img class="align-end text-white" height="300" src="@/assets/mouMajalengka.jpg"></v-img>
          <v-row>
            <v-col cols="10" md="10">
              <h3>MOU and Visit to Majalengka University</h3>
            </v-col>
            <v-col cols="1" md="1">
              <v-icon class="pt-5" icon="fa-solid fa-chevron-right fa-2xs" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" lg="3">
        <v-card variant="outlined-white">
          <v-img class="align-end text-white" height="300" src="@/assets/baliMarwadewa.jpg"></v-img>
          <v-row>
            <v-col cols="10" md="10">
              <h3>Visit to Warmadewa University</h3>
            </v-col>
            <v-col cols="1" md="1">
              <v-icon class="pt-5" icon="fa-solid fa-chevron-right fa-2xs" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" lg="3">
        <v-card variant="outlined-white">
          <v-img
            class="align-end text-white"
            height="300"
            src="@/assets/pelatihanKatolikWiraMandira.jpg"
          ></v-img>
          <v-row>
            <v-col cols="10" md="10">
              <h3>Training about journal in Wira Mandira Catholic University</h3>
            </v-col>
            <v-col cols="1" md="1">
              <v-icon class="pt-5" icon="fa-solid fa-chevron-right fa-2xs" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.v-container.vv {
  max-width: 100%;
}

.v-container.vu {
    padding: 10px;
}

/* Tablet */
@media (min-width:600px) {
  .v-container.vu {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width:900px) {
  .v-container.vu {
    max-width: 1281px;
  }
}
.v-img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.v-img:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}


h1 {
  font-size: 40px;
  font-family: Montserrat;
}
h2 {
  font-size: 24px;
  font-family: Montserrat;
  font-weight: bold;
}

h3 {
  font-size: 16px;
  font-family: Montserrat;
font-style: Regular;
font-weight:normal;

  color: #858383;
}
</style>

// <router-link to="/stories" custom v-slot="{ navigate }"></router-link>