<template>
  <v-container id="porto" class="hhh">
    <v-container class="hb">
      <div class="title text-center">
        <h1 class="pt-10">Our Portofolio</h1>
        <h2
          style="color:#858383"
        >The following are some of the projects we have completed, where the projects are in the form of websites and mobile applications</h2>
      </div>
      <v-row justify="center" class="pt-10">
        <v-col cols="12" md="4" lg="4" class="mr-0">
          <v-card class="pa-5 rounded-xl" elevation="5">
            <v-img style="position: relative" src="@/assets/project1.png"></v-img>
            <h2>Landing Page PT. KSI</h2>
            <h3>Is a special web page specifically designed for creating campaigns or advertisements for a business from the company PT. KSI</h3>
            <v-btn
              href="https://www.strategis.co.id/"
              target="_blank"
              variant="outlined"
              rounded
            >Go To Project</v-btn>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4" class="mr-0">
          <v-card class="pa-5 rounded-xl" elevation="5">
            <v-img style="position: relative" src="@/assets/project2.png"></v-img>
            <h2>Acitya Journal</h2>
            <h3>Is a scientific journal management website that covers aspects of online journal publishing, from creating a journal website to operational tasks.</h3>
            <v-btn
              href="https://acityajournal.com/"
              target="_blank"
              variant="outlined"
              rounded
            >Go To Project</v-btn>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4" class="mr-0">
          <v-card class="pa-5 rounded-xl" elevation="5">
            <v-img style="position: relative" src="@/assets/project3.png"></v-img>
            <h2>Catra Research Institute</h2>
            <h3>Is a special web page specifically designed for creating campaigns or advertisements for a business from the Catra Research Institute</h3>
            <v-btn
              href="https://catrainstitute.co.id/"
              target="_blank"
              variant="outlined"
              rounded
            >Go To Project</v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" class="pb-10">
        <v-col cols="12" md="4" lg="4" class="mr-0">
          <v-card class="pa-5 rounded-xl" elevation="5">
            <v-img style="position: relative" src="@/assets/project4.png"></v-img>
            <h2>Landing Page Vikrama</h2>
            <h3>Is a special web page specifically designed for creating campaigns or advertisements for a business from the Vikrama Research Institute</h3>
            <v-btn
              href="https://vikramainstitute.co.id/"
              target="_blank"
              variant="outlined"
              rounded
            >Go To Project</v-btn>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4" class="mr-0">
          <v-card class="pa-5 rounded-xl" elevation="5">
            <v-img style="position: relative" src="@/assets/project5.png"></v-img>
            <h2>Journal Keberlanjutan</h2>
            <h3>Is a scientific journal management website that covers aspects of online journal publishing, from creating a journal website to operational tasks</h3>
            <v-btn
              href="https://journalkeberlanjutan.com/"
              target="_blank"
              variant="outlined"
              rounded
            >Go To Project</v-btn>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4" class="mr-0">
          <v-card class="pa-5 rounded-xl" elevation="5">
            <v-img style="position: relative" src="@/assets/project6.png"></v-img>
            <h2>CakraCik</h2>
            <h3>Is an e-commerce application in which we as consumers can buy chicken meat</h3>
            <br />
            <v-btn
              href="https://play.google.com/store/apps/details?id=com.cakracik.app&hl=id"
              target="_blank"
              variant="outlined"
              rounded
            >Go To Project</v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.v-container.hhh {
  max-width: 100%;
}

/* .v-container.hb {
  padding: 10px;
} */

/* Tablet */
@media (min-width: 600px) {
  .v-container.hb {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.hb {
    max-width: 1281px;
  }
}

h1 {
  font-size: 40px;
  font-family: Montserrat;
}
h2 {
  font-size: 20px;
  font-family: Montserrat;
  font-weight: bold;
  color: #1c6758;
  padding-top: 15px;
}

h3 {
  font-size: 14px;
  font-family: Montserrat;
  font-style: Regular;
  font-weight: normal;
  color: #858383;
  padding-top: 10px;
  padding-bottom: 40px;
}
.v-btn:hover {
  background-color: #1c6758;
  color: #eef2e6;
}

.v-btn:active {
  background-color: #1c6758;
}
.v-btn {
  color: #1c6758;
  text-transform: none !important;
}
.v-card:hover {
  background-color: #eef2e6;
  color: rgb(19, 19, 19);
}

.v-card {
  height: 400px;
  width: 366px;
}

.v-img {
  width: 325px;
  height: 155px;
}
</style>