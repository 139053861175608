<template>
  <div>
    <v-navigation-drawer
      class="text-center deep-purple accent-4"
      v-model="drawer"
      app
      temporary
      dark
      src="@/assets/ksiputih.png"
    >
      <v-list>
        <v-list-item>
          <v-list-item>
            <img src="LogoB.png" alt="Logo" />
          </v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">PT.KSI</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list dense>
        <v-list-item>
          <router-link to="/" custom v-slot="{ navigate }">
            <v-btn class="text-black" @click="navigate" role="link">Home</v-btn>
          </router-link>
          <br />
          <br />
          <v-btn class="text-black" text @click="scroll('how')">Service</v-btn>
          <br />
          <br />
          <router-link to="/about" custom v-slot="{ navigate }">
            <v-btn class="text-black" @click="navigate" role="link">About Us</v-btn>
          </router-link>
          <br />
          <br />
          <v-btn class="text-black" text @click="scroll('footer')">Contact</v-btn>
          <br />
          <br />
          <v-btn class="text-black" text @click="scroll('porto')">Portofolio</v-btn>
          <br />
          <br />
          <v-menu open-on-click>
          <template v-slot:activator="{ props }">
            <v-btn class="text-black" v-bind="props">Program</v-btn>
          </template>
          <v-list>
            <v-list-item>
              <router-link to="/training" custom v-slot="{ navigate }">
                <v-btn class="a text-black" variant="plain " @click="navigate" role="link">Training</v-btn>
              </router-link>
              <br />
              <router-link to="/" custom v-slot="{ navigate }">
                <v-btn variant="plain" class="a text-black" @click="navigate" role="link">Webinar Series</v-btn>
              </router-link>
            </v-list-item>
          </v-list>
        </v-menu>
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">
              {{
              text
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar dark flat class="px 12">
      <v-img class="mx-10" src="../assets/ksiputih.png" max-height="100" max-width="100"></v-img>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="mr-4 text-white" v-if="isXs" />

      <div v-else>
        <router-link to="/" custom v-slot="{ navigate }">
          <v-btn @click="navigate" role="link">Home</v-btn>
        </router-link>
        <!-- <v-btn text @click="scroll('#HomeView')" class="text-white">Home</v-btn> -->
        <v-btn @click="scroll('how')">Service</v-btn>
        <router-link to="/about" custom v-slot="{ navigate }">
          <v-btn @click="navigate" role="link">About Us</v-btn>
        </router-link>
        <!-- <router-link to="/stories" custom v-slot="{ navigate }">
          <v-btn  @click="navigate" role="link"> Stories and Visit </v-btn>
        </router-link>-->
        <!-- <v-btn text @click="scroll('home')" class="text-white">About Us</v-btn> -->
        <v-btn @click="scroll('footer')">Contact</v-btn>
        <v-btn @click="scroll('porto')">Portofolio</v-btn>
        <v-menu open-on-hover>
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props">Program</v-btn>
          </template>
          <v-list>
            <v-list-item>
              <router-link to="/training" custom v-slot="{ navigate }">
                <v-btn class="a" variant="plain" @click="navigate" role="link">Training</v-btn>
              </router-link>
              <br />
              <router-link to="/webinar" custom v-slot="{ navigate }">
                <v-btn variant="plain" class="a" @click="navigate" role="link">Webinar Series</v-btn>
              </router-link>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-spacer></v-spacer>

      <v-btn @click="scroll('home')">English</v-btn>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    isXs: false
    // items: [
    //   { title: 'Click Me' },
    //     { title: 'Click Me' },
    //     { title: 'Click Me' },
    //     { title: 'Click Me 2' },
    // ],
  }),
  props: {
    color: String,
    flat: Boolean
  },
  methods: {
    scroll(refName) {
      const element = document.getElementById(refName);
      element.scrollIntoView({ behavior: "smooth" });
    },
    onResize() {
      this.isXs = window.innerWidth < 850;
    }
  },

  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  }
};
</script>

<style scoped>
.v-app-bar {
  background: linear-gradient(500deg, #1c6758 15%, #3d8361 50%);
}
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}

.span {
  color: green;
}

.v-btn:hover {
  background-color: #d6cda4;
  color: #3d8361;
}

.v-btn:focus {
  background-color: #d6cda4;
  color: #3d8361;
}

.v-btn:active {
  background-color: #d6cda4;
  color: #3d8361;
}
.v-btn {
  text-transform: none !important;
  color: floralwhite;
}

.v-btn.a:hover {
  background-color: #d6cda4;
  color: #3d8361;
}

.v-btn:focus {
  background-color: #d6cda4;
  color: #3d8361;
}

.v-btn:active {
  background-color: #d6cda4;
  color: #3d8361;
}
.v-btn.a {
  text-transform: none !important;
  color: #3d8361;
}
</style>

