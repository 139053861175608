<template>
  <v-container id="how" class="hhh">
    <v-container class="hb">
      <div class="title">
        <h1 class="pt-10 text-center">How can we help your Business ?</h1>
        <h3 class="text-center">
          We build readymade websites, Mobile Applications, Research and Development,
          and Assessment
        </h3>
        <br />
      </div>
      <v-row justify="center">
        <v-col cols="12" md="4" lg="4" class="ma-0">
          <v-card class=" rounded-xl" elevation="5">
            <v-img
              class="mx-auto cover"
              style="position: relative"
              src="@/assets/how/1Website.jpg"
              max-height="300"
              max-width="371"
            ></v-img>
            <h2 >Website Development</h2>
            <h3 >Website Development, Solution for your business</h3>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4" class="ma-0">
          <v-card class=" rounded-xl" elevation="5">
            <v-img
              class="mx-auto"
              style="position: relative"
              src="@/assets/how/2Mobile.jpg"
              max-height="300"
              max-width="371"
            ></v-img>
            <h2>Mobile App Development</h2>
            <h3>Mobile application development solutions for your business</h3>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4" class="ma-0">
          <v-card class=" rounded-xl" elevation="5">
            <v-img
            cover
              class="mx-auto"
              style="position: relative"
              src="@/assets/how/3Business.jpg"
              height="245px"
            ></v-img>
            <h2>Business Research and Development</h2>
            <h3>Help solve problems, increase knowledge, make better interpretations and discover new facts.</h3>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="4" lg="4" class="ma-0">
          <v-card class=" rounded-xl" elevation="5">
            <v-img
              class="mx-auto"
              style="position: relative"
              src="@/assets/how/4Sustainability.jpg"
              max-height="300"
              max-width="371"
            ></v-img>
            <h2>Taxation and Finance</h2>
            <h3>In providing guarantees for the Sustainability Report, an independent party is needed to provide it</h3>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4" class="ma-0">
          <v-card class=" rounded-xl" elevation="5">
            <v-img
              class="mx-auto"
              style="position: relative"
              src="@/assets/how/5Sustainable.jpg"
              max-height="300"
              max-width="371"
            ></v-img>
            <h2>Sustainable Finance</h2>
            <h3>Offering training related to the implementation of sustainable finance assisting in the preparation of the Sustainable Finance Action Plan (RAKB)</h3>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4" class="ma-0">
          <v-card class=" rounded-xl" elevation="5">
            <v-img
              class="mx-auto"
              style="position: relative"
              src="@/assets/how/6Annual.jpg"
              max-height="300"
              max-width="371"
            ></v-img>
            <h2>Annual and Sustainability Report</h2>
            <h3>The annual report is prepared using the Annual Report Award (ARA) criteria</h3>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="4" lg="4" class="ma-0">
          <v-card class=" rounded-xl" elevation="5">
            <v-img
              class="mx-auto"
              style="position: relative"
              src="@/assets/how/7GCG.jpg"
              max-height="300"
              max-width="371"
            ></v-img>
            <h2>GCG Assesment</h2>
            <h3>Good Corporate Governance Assessment for companies within BUMN uses instruments that have been developed by the Ministry of BUMN</h3>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4" class="ma-0">
          <v-card class=" rounded-xl" elevation="5">
            <v-img
              class="mx-auto"
              style="position: relative"
              src="@/assets/how/8Phyton.jpg"
              max-height="300"
              max-width="371"
            ></v-img>
            <h2>Phyton For Data Science</h2>
            <h3>Solving a problem by identifying and evaluating problems, opportunities, obstacles that occur</h3>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4" class="ma-0">
          <v-card class=" rounded-xl" elevation="5">
            <v-img
              class="mx-auto"
              style="position: relative"
              src="@/assets/how/9Brand.jpg"
              height="250px"
            ></v-img>
            <h2>Brand Management</h2>
            <h3>Assisting clients in compiling a Brand Management Strategy to increase the value generated</h3>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.v-container.hhh {
  max-width: 100%;
}
/* 
.v-container.hb {
    padding: 10px;
} */

/* Tablet */
@media (min-width: 600px) {
  .v-container.hb {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.hb {
    max-width: 1281px;
  }
}

h1 {
  font-size: 40px;
  font-family: Montserrat;
}

h2 {
  font-size: 20px;
  font-family: Montserrat;
  font-weight: bold;
  color: #1C6758;
  padding-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

h3 {
  font-size: 14px;
  font-family: Montserrat;
  font-style: Regular;
  font-weight: normal;
  color: #858383;
  margin-left: 10px;
  margin-right: 10px;
}

/* h2 {
  font-size: 24px;
  font-family: Montserrat;
  font-weight: bold;
  line-height: normal;
}

h3 {
  font-size: 16px;
  font-family: Montserrat;
  font-style: Regular;
  font-weight: normal;
  color: #858383;
} */

.v-card:hover {
  background-color: #eef2e6;
  color: rgb(19, 19, 19);
}

.v-img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.v-img:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.1);
}
.v-card {
  height: 380px;
  width: 366px;
}

</style>