<template>
  <div id="home">
  <v-container class="hh">
  <v-container class="he">
    <v-row class="pt-5">
      <v-col cols="12" md="6" >
        <div class="mt-16 animate__animated animate__backInLeft">
          <h1 class="a ">Developing solutions</h1>
          <h1 class="a">for the future</h1>
          <h4 >
            We help our clients identify their issues, develope solutions and
            take action
          </h4>
          <br/>
          <v-btn
            href="https://api.whatsapp.com/send/?phone=6281120011644&text&type=phone_number&app_absent=0"
            variant="outlined"
            rounded
          >
            Contact Us
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <v-img
          class="pt-10 animate__animated animate__backInRight"
          style="position: relative"
          src="@/assets/hero_img.png"
          max-height="602"
          max-width="602"
        ></v-img>
      </v-col>
    </v-row>

    <!-- <v-row>
          <v-col cols="6" class="a">
            <div style="position: relative" class="a ">
               
            </div>
            <div style="position: relative" class="a ">
            
            </div>
            <div style="position: relative" class="b">
             
            </div>
            <div style="position: relative" class="b">
              develop solutions and take action
            </div>
            <router-link to="/about" custom v-slot="{ navigate }">
              <v-btn variant="outlined" rounded @click="navigate" role="link">
                <h2>Contact Us</h2>
              </v-btn>
            </router-link>
          </v-col>
          <v-col cols="6">
            <v-img
              style="position: relative"
              src="@/assets/hero_img.png"
              max-height="800"
              max-width="800"
            ></v-img>
          </v-col>
        </v-row> -->
  </v-container>
</v-container>
</div>
</template>

<script>
export default {};
</script>

<style scoped>
.a {
  font-size: 55px;
  color: white;
}

h4 {
  font-size: 1vw;
  color: white;
}

@media (max-width: 991px){
  .a{
    font-size: 2rem;
  }
  h4 {
  font-size: 1rem;
  color: white;
}
}

.v-container.hh {
  background-image: url("@/assets/bghome (1).png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 100%;
  /* padding-bottom: 85px; */
}

.v-container.he {
    padding: 10px;
}

/* Tablet */
@media (min-width:600px) {
  .v-container.he {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width:900px) {
  .v-container.he {
    max-width: 1281px;
  }
}
.v-btn:hover {
  background-color: #d6cda4;
  color: #3d8361;
}

.v-btn:focus {
  background-color: #d6cda4;
}

.v-btn:active {
  background-color: #d6cda4;
}
.v-btn {
  color: #d6cda4;
  text-transform: none !important;
}
</style>