<template>
  <v-container class="osu">
  <v-container class="os">
    <v-card class="mx-auto rounded-xl" elevation="5">
      <v-row class="pa-10">
        <v-col cols="12" md="3">
          <div class="pa-0">
             <h1> Our subsidiary</h1>
            <h2> Below are some of the our subsidiary</h2>
          </div>
          <!-- <h2 ></h2>
            <br />
            <h4>Below are some of the our Division Business</h4> -->
        </v-col>
        <v-col cols="12" md="3">
          <v-img
          class="pa-5"

            style="position: relative"
            src="@/assets/ADPSI_Logo.png"
            max-height="800"
            max-width="800"
          ></v-img>
        </v-col>
        <v-col cols="12" md="3">
          <v-img
          class="pa-5"

            style="position: relative"
            src="@/assets/Jari_LOgo.png"
            max-height="800"
            max-width="800"
          ></v-img>
        </v-col>
        <v-col cols="12" md="3">
          <v-img
          class="pa-5"

            style="position: relative"
            src="@/assets/LSP_Logo.png"
            max-height="350"
            max-width="350"
          ></v-img>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.v-container.osu {
  max-width: 100%;
}

.v-container.os {
    padding: 10px;
}

/* Tablet */
@media (min-width:600px) {
  .v-container.os {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width:900px) {
  .v-container.os {
    max-width: 1281px;
  }
}

.v-container {
  padding-top: 50px;
  padding-bottom: 50px;
}

h1 {
  font-size: 34px;
  font-family: Montserrat;
}
h2 {
  font-size: 18px;
  font-family: Montserrat;
  color: #858383;
  font-style: Regular;
}

/* @media (max-width: 991px){
  h1{
    font-size: 1rem;
  }
  h4 {
  font-size: 1rem;
  
}
} */
.v-img:hover {
  background-color: #eef2e6;
}
</style>
